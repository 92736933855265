<template>
  <div class="home">
    <div class="container">
      <div class="row" style="margin: 50px 0;">
        <h1>Validace IČO</h1>
      </div>
      <div class="row" style="margin: 50px 0;">
        <ul>
          <li>Data jsou stahována z <a href="https://wwwinfo.mfcr.cz/ares/ares_xml.html.cz" target="_blank">Aresu</a>. Konkrétně jde o výpis dat z Registru živnostenského podnikání.</li>
          <li>Seznam IČO, který chcete zkontrolovat vkládejte do levého boxu. Jeden řádek = jedno IČO.</li>
          <li>Jsou zde kontrolovány tři stavy:
            <ul>
              <li>Zda IČO existuje</li>
              <li>Zda je živnost aktivní</li>
              <li>Zda byla někdy živnost přerušená + od kdy začalo poslední přerušení.</li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="row">
        <div class="col-6">
          <label for="inputArea" class="form-label">Seznam IČO</label>
          <textarea class="form-control" id="inputArea" rows="20" required placeholder="123456"></textarea>
          <button style="margin: 15px 0;" type="button" class="btn btn-dark" v-on:click="start"> Start </button>
        </div>
        <div class="col-6">
          <label for="outputArea" class="form-label">Výsledky</label>
          <textarea class="form-control" id="outputArea" rows="20" readonly></textarea>
          <span v-if="counter">Zpracováno {{counter}} z {{inputData.length}} </span>
          <button style="margin: 15px 0;" type="button" class="btn btn-dark" v-on:click="clearResults"> Smazat výsledky </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import { parseString } from 'xml2js'
import Ares from 'ares-data'


export default {

  name: 'Home',
  data () {
    return {
      inputData: null,
      counter: 0
    }
  },mounted() {
    let ares = new Ares('https://ares.nedi.cz/proxy.php');
    ares.findByIdentification('08230773', function(data, err) {
      // do somethincong with loaded data
      console.log(data)
    });
  },

  methods: {
    getInputData () {
      this.inputData = document.getElementById('inputArea').value.split('\n');
    },
    clearResults () {
      document.getElementById("outputArea").value = '';
      this.counter = 0;
    },
  pushOut (line) {
    let currentContent = document.getElementById("outputArea").value;
    let newContent;
    if (currentContent != '') {
      newContent = currentContent + '\n' + line;
    }
    else {
      newContent = line;
    }
    document.getElementById("outputArea").value = newContent;
  },
    async resolve (id) {
      try {
        let _vue = this;
        await axios.get('/proxy.php', {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'X-Proxy-URL': 'https://wwwinfo.mfcr.cz/cgi-bin/ares/darv_rzp.cgi?ico=' + id,
          },
        })
                .then(function (response) {
                  //console.log(response.data);
                  let xml = response.data
                  let data;
                  parseString(xml, {ignoreAttrs: true}, function (err, result) {
                    data = result;
                  });
                  if ('D:E' in data['are:Ares_odpovedi']['are:Odpoved'][0]) {
                    _vue.pushOut('ICO: ' + id +' - OBJEKT NENALEZEN');
                  } else {
                    let res = data['are:Ares_odpovedi']['are:Odpoved'][0]['D:Vypis_RZP'][0]['D:ZI'][0]['D:Z'][0];
                    //console.info(res)
                    if ('D:Preruseni_od' in res) {
                      _vue.pushOut('ICO: ' + id +' - někdy byla přerušená činnost, naposledy od: ' + res['D:Preruseni_od']);
                    } else {
                      _vue.pushOut('ICO: ' + id +' - aktivní činnost');
                    }
                  }

                })
                .catch(function (error) {
                  console.log(id,error);
                })
        this.counter++;
      } catch (e) {
        console.log(e)
      }
    },
    start () {
      this.getInputData();
      for (let i = 0; i < this.inputData.length; i++) {
        this.resolve(this.inputData[i]);
      }
    }
  }
}
</script>
<style lang="less">
  ul, li, ol {
    text-align: left;
  }
</style>
